<template>
  <cks-page-wrap v-loading="loading" style="padding-bottom: 100px;">
    <cks-edit-form
      @updatePosi="updatePosi"
      :isPosi="true"
      title="经典案例编辑"
      @save="save"
      ref="formRef"
      label-position="top"
      :rules="rules"
      :model="form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="案例名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="案例类型" prop="caseType">
            <cks-select class="cks-size--fw" v-model="form.caseType" :options="caseTypeList"></cks-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="id">
          <el-form-item label="创建日期" prop="createdTime">
            <el-input disabled v-model="form.createdTime"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="id">
          <el-form-item label="更新日期" prop="updatedTime">
            <el-input disabled v-model="form.updatedTime"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="briefText">
            <template #label>
              <span style="color: red;">*</span> 案例介绍
            </template>
            <div v-if="temp" class="posi"></div>
            <quill-editor ref="quillEditorRef" v-model="form.briefText" :options="editorOption"></quill-editor>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="标题图片(1张, 建议图片尺寸520px * 380px, 不应超过1M大小)" prop="titlePic">
            <cks-upload v-model="form.titlePic" @updateUrl="updateUrl" :fileUrl="form.titlePic" :limit="1"></cks-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="关联视频(建议视频格式.mp4, 不应超过300M大小)" prop="linkedVideo">
            <cks-upload-video v-model="form.linkedVideo" @deleteUrl="deleteUrl" @updateUrl="updateUrl2" :fileUrl="form.linkedVideo" :limit="1"></cks-upload-video>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="关联图片(1~6张, 建议图片大小1060px * 600px, 不应超过1M大小)" prop="linkedPic">
            <cks-upload v-model="form.linkedPic" @updateUrl="updateUrl3" :fileUrl="form.linkedPic" :limit="6"></cks-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </cks-edit-form>
  </cks-page-wrap>
</template>

<script>
import {
  toRefs, reactive, onMounted,
} from 'vue';
import {
  showSuccess, getRequireRule, showError,
} from '@/utils';
import apis from '@/request/apis';
import { useRouter } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  components: {
    QuillEditor,
  },
  props: ['id'],
  setup(props) {
    const router = useRouter();
    const caseTypeList = [
      {
        label: '0',
        name: '星级酒店',
      },
      {
        label: '1',
        name: '物流冷链',
      },
      {
        label: '2',
        name: '中央厨房',
      },
      {
        label: '3',
        name: '民生工程',
      },
      {
        label: '4',
        name: '酒管冷链',
      },
      {
        label: '5',
        name: '文旅冷链',
      },
      {
        label: '6',
        name: '冷链智慧维保',
      },
    ];
    const data = reactive({
      formRef: null,
      form: {
        caseType: '2',
      },
      rules: {
        name: [getRequireRule('案例名称')],
        caseType: [getRequireRule('案例类型')],
        titlePic: [getRequireRule('标题图片')],
        linkedPic: [getRequireRule('关联图片')],
        // briefText: [getRequireRule('案例介绍')],
        // linkedVideo: [getRequireRule('关联视频')],
      },
      loading: false,
      temp: false,
      quillEditorRef: null,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
            ],
            [
              { color: [] },
              { background: [] },
            ],
            ['image'],
          ],
        },
      },
    });

    async function save(done) {
      try { await data.formRef.validate(); } catch (e) { return done(false); }
      if (data.quillEditorRef.getHTML() === '<p><br></p>') {
        done(false);
        showError(new Error('案例介绍不能为空!'));
        return;
      }
      try {
        await apis.classicCases.save({
          ...data.form,
          briefText: data.quillEditorRef.getHTML(),
        });
        showSuccess('保存成功');
        done();
        router.push({
          name: 'classicCases',
        });
      } catch (e) {
        done(e);
      }
    }

    async function loadDetail() {
      data.loading = true;
      try {
        const res = await apis.classicCases.getDetail({ id: props.id });
        res.caseType = String(res.caseType);
        data.form = res;
        if (data.form.briefText) {
          data.quillEditorRef.setHTML(data.form.briefText);
        }
      } finally {
        data.loading = false;
      }
    }

    onMounted(() => {
      if (props.id) {
        loadDetail();
      } else {
        props.id || data.formRef?.unlock();
      }
    });

    const updatePosi = (val = false) => {
      data.temp = val;
    };
    const updateUrl = (val) => {
      data.form.titlePic = val;
    };
    const updateUrl2 = (val) => {
      data.form.linkedVideo = val;
    };
    const updateUrl3 = (val) => {
      data.form.linkedPic = val;
    };

    const deleteUrl = () => {
      data.form.linkedVideo = '';
    };
    return {
      save,
      ...toRefs(data),
      updatePosi,
      caseTypeList,
      updateUrl,
      updateUrl2,
      updateUrl3,
      deleteUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .quill-editor {
  height: 400px;
}
::v-deep .ql-container{
  height: 300px;
}
.posi {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 20px;
  z-index: 999;
  background-color: #f5f7fa;
  opacity: 0.5;
  cursor:not-allowed;
}
.el-input {
  --el-input-focus-border: #2F51FF;
}
</style>
